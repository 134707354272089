import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getPayment } from '../api-client'
import Error from '../components/Error'
import Info from '../components/Info'
import Spinner from '../components/Spinner'
import logo from '../images/snapticket.png'

const timeouts = [1, 2, 3, 5, 8]

const ConfirmPaymentPage = () => {
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const [searchParams] = useSearchParams()
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [infoMessage, setInfoMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [count, setCount] = useState<number>(0)
  const [payment, setPayment] = useState<any>(null)

  const setTimeoutID = (timeoutID: NodeJS.Timeout | null) => {
    timeoutIDRef.current = timeoutID
  }

  const onRetry = () => {
    setInfoMessage(null)
    pollPayment()
  }

  const pollPayment = async () => {
    if (paymentId) {
      const payment = await getPayment(paymentId)
      setPayment(payment)
      if (payment.status === 'paid') {
        // Redirect to vivenu success
        window.location = payment.successReturnUrl
      } else if (payment.status === 'expired' || payment.status === 'failed' || payment.status === 'canceled') {
        // Redirect to vivenu failure
        window.location = payment.failureReturnUrl
      } else if (payment.status === 'open') { 
        setCount(count + 1)       
      } else {
        setCount(0) 
        // Payment should be refunded, show appropriate message.
        setErrorMessage(
          `Leider konnte die Zahlung nicht im Snapticket-Ticketsystem bestätigt werden.
          Bitte kontaktieren Sie payments@snapticket.de. 
          Geben Sie die folgenden Einzelheiten an:`
        )
      }
    }
  } 

  useEffect(() => {
    if (count === 5) {
      setCount(0)
      setInfoMessage('Leider konnte die Zahlung nicht bestätigt werden.')
    } else if (count !== 0) { 
      const timeoutID = setTimeout(() => {
        pollPayment()
      }, timeouts[count] * 1000)
      setTimeoutID(timeoutID)
    }
  }, [count])

  useEffect(() => {
    if (paymentId) {
      const timeoutID = setTimeout(() => {
        pollPayment()
      }, timeouts[count] * 1000)
      setTimeoutID(timeoutID)
    }
  }, [paymentId])

  useEffect(() => {
    const pId = searchParams.get('paymentId')
    if (pId !== paymentId) {
      setPaymentId(pId)
    }
  }, [])

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen flex flex-col justify-center">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt=""
        />
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h1 className="text-3xl font-medium text-gray-900">Zahlung wird bestätigt.</h1>
        <p className="mt-2.5 text-xl text-gray-500">Sobald die Zahlung bestätigt wurde, werden Sie zurück in den Ticketshop geleitet.</p>
        {errorMessage && payment && (
          <Error className="mt-12 text-left" message={errorMessage}>
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>{`Mollie Zahlungs-ID: ${payment!.mollieId}`}</li>
              <li>{`Buchungsnummer: ${payment!.transactionId}`}</li>
            </ul>
          </Error>
        )}
        {!errorMessage && !infoMessage && (<Spinner className="mt-12" />)}
        {infoMessage && (
          <>
            <Info className="mt-12 text-left" message={infoMessage} additionalMessage="Bitte versuchen Sie es erneut oder kontaktieren Sie payments@snapticket.de." />
            <button
              type="button"
              className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={onRetry}
            >
              Bitte erneut versuchen
            </button>
          </>
        )}
      </div> 
    </div>
  )
}

export default ConfirmPaymentPage