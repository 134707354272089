const getStatus = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/status?paymentId=${paymentId}`)

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    throw new Error(message)
  }

  const { alive } = await response.json()

  return alive
}

const getPayment = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/payment?paymentId=${paymentId}`)

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    throw new Error(message)
  }

  const payment = await response.json()

  return payment
}

const createMolliePayment = async (paymentId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/payment/gateway?paymentId=${paymentId}`)

    if (!response.ok) {
      const body = await response.json()
      throw new Error(body.error)
    }

    const { redirectUrl } = await response.json()

    return redirectUrl
  } catch (error) {
    throw error
  }
  
}

export { createMolliePayment, getPayment, getStatus }