import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { createMolliePayment, getStatus } from '../api-client'
import Error from '../components/Error'
import Spinner from '../components/Spinner'
import logo from '../images/snapticket.png'

const VerifyPaymentPage = () => {
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const [searchParams] = useSearchParams()
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [retry, setRetry] = useState<boolean>(false)
  const [dbStatusRetries, setDbStatusRetries] = useState<number>(0)
  const [retryDbStatus, setRetryDbStatus] = useState<boolean>(false)

  const setTimeoutID = (timeoutID: NodeJS.Timeout | null) => {
    timeoutIDRef.current = timeoutID
  }

  const getDbStatus = async () => {
    try {
      const alive = await getStatus(paymentId!)
      if (alive) {
        await getRedirectUrl()
      }
    } catch (error: any) {
      setDbStatusRetries(dbStatusRetries + 1)
    }
  }

  const getRedirectUrl = async () => {
    try {
      const redirectUrl = await createMolliePayment(paymentId!)
      window.location = redirectUrl
    } catch (error: any) {
      if (error.message === 'PAYMENT_ALREADY_PROCESSED') {
        setErrorMessage(`Die Zahlung mit der ID ${paymentId} wurde bereits abgeschlossen. Bitte kontaktieren Sie payments@snapticket.de. `)
      } else if (error.message === 'INTERNAL_SERVER_ERROR') {
        setErrorMessage(`Ein unbekannter Fehler ist aufgetreten. Die Zahlung mit der ID ${paymentId}  konnte nicht verifiziert werden. Bitte kontaktieren Sie payments@snapticket.de.`)
      } else {
        // setRetry(true)
        // setErrorMessage(`Something went wrong... The payment with id ${paymentId} couldn't be verified. Please contact payments@snapticket.de.`)
        const timeoutID = setTimeout(() => {
          delayedRetry(paymentId as string)
        }, 10000)
        setTimeoutID(timeoutID)
      }
      console.log(error.message)
    }
  }

  const delayedRetry = (paymentId: string) => {
    setRetry(true)
    setErrorMessage(`Ein unbekannter Fehler ist aufgetreten. Die Zahlung mit der ID ${paymentId}  konnte nicht verifiziert werden. Bitte kontaktieren Sie payments@snapticket.de.`)
  }

  const onRetry = () => {
    setRetry(false)
    setErrorMessage(null)
    getRedirectUrl()
  }

  const onRetryDbStatus = () => {
    setRetryDbStatus(false)
    setErrorMessage(null)
    getDbStatus()
  }

  useEffect(() => {
    if (dbStatusRetries > 0 && dbStatusRetries < 5) {
      getDbStatus()
    } else if (dbStatusRetries !== 0) {
      setDbStatusRetries(0)
      setRetryDbStatus(true)
      setErrorMessage(`Ein unbekannter Fehler ist aufgetreten. Die Zahlung mit der ID ${paymentId}  konnte nicht verifiziert werden. Bitte versuchen Sie es erneut.`)
    }
  }, [dbStatusRetries])

  useEffect(() => {
    if (paymentId != null) {
      getDbStatus()
    }
  }, [paymentId])

  useEffect(() => {
    const pId = searchParams.get('paymentId')
    if (pId !== paymentId) {
      setPaymentId(pId)
    } else if (pId == null) {
      setErrorMessage('Für die Verifizierung einer Zahlung ist eine Payment ID notwendig.')
    }
  }, [])

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen flex flex-col justify-center">
      <div className=" px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt=""
        />
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h1 className="text-3xl font-medium text-gray-900">Zahlung wird überprüft.</h1>
        <p className="mt-2.5 text-xl text-gray-500">Nach erfolgreicher Prüfung werden Sie zum Zahlungsdienst Mollie weitergeleitet.</p>
        {errorMessage && (<Error className="mt-12 text-left" message={errorMessage} />)}
        {!errorMessage && (<Spinner className="mt-12" />)}
        {retry && (
          <button
            type="button"
            className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={onRetry}
          >
            Bitte erneut versuchen
          </button>
        )}
        {retryDbStatus && (
          <button
            type="button"
            className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={onRetryDbStatus}
          >
            Bitte erneut versuchen
          </button>
        )}
      </div> 
    </div>
  )
}

export default VerifyPaymentPage