import React from 'react'
import { XCircleIcon } from '@heroicons/react/solid'

type Props = {
  children?: React.ReactNode
  className?: string
  message: string
}

const Error: React.FC<Props> = ({ className='', children, message }) => (
  <div className={`${className} rounded-md bg-red-50 p-4`}>
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">{message}</h3>
        {children && (<div className="mt-2 text-sm text-red-700">
          {children}
        </div>)}
      </div>
    </div>
  </div>
)

export default Error
