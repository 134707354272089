import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/solid'

type Props = {
  className?: string
  message: string
  additionalMessage?: string
}

const Info: React.FC<Props> = ({ className='', message, additionalMessage }) => (
  <div className={`${className} rounded-md bg-blue-50 p-4`}>
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between flex-col">
        <p className="text-sm text-blue-700">{message}</p>
        {additionalMessage && (
          <p className="mt-3 text-sm">
            {additionalMessage}
          </p>
        )}
      </div>
    </div>
  </div>
)

export default Info
