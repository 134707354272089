import React from 'react'
import { Routes, Route } from 'react-router-dom'
import VerifyPaymentPage from './pages/VerifyPaymentPage'
import ConfirmPaymentPage from './pages/ConfirmPaymentPage'

const App =() => (
  <Routes>
    <Route path="/verify-payment" element={<VerifyPaymentPage />} />
    <Route path="/confirm-payment" element={<ConfirmPaymentPage />} />
  </Routes>     
)

export default App
